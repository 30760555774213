import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { debounce } from 'lodash';
import QuestionPopup from '../UI/QuestionPopup';
import WarningPopup from '../UI/WarningPopup';
import { patientFormTranslatedStrings as messages } from '../../translations/PatientFormTranslations';
import {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelConflictPopup,
  userClickedOnConfirmConflictPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient,
  userCallClearPatientAction,
  userCallSearchPatient,
  userConfirmedClearingForm,
  updateHostPatient,
  userLoadedExistingPatient,
  saveSuppressClearPatientFieldsDialog,
  setSuppressClearPatientFieldsDialog
} from '../../redux/feature/patient-management/patient-management.actions';
import TranslatedContent from '../Translations/TranslatedContent';
import DynamicInlineInputField from '../DynamicInlineInputField/DynamicInlineInputField';
import Alert from '../UI/Alert';
import utils from '../../utils/utils';
import styles from './PatientManagementInlineForm.module.scss';
import { FieldName } from '../../utils/enums';
import InlineHeader from '../UI/HeaderInline';
import LinkLabel from '../UI/LinkLabel';
import { fullDateOfBirthMask } from '../../consts';
import { NotificationIcons } from '../UI/Notification/notificationIconsTypes';

class PatientInlineForm extends React.Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    patientId: PropTypes.string,
    companyId: PropTypes.string,
    patientType: PropTypes.string,
    config: PropTypes.object,
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    formFields: PropTypes.array.isRequired,
    conflictedPatient: PropTypes.object,
    patientCancelButtonVariant: PropTypes.string,
    errorConflictAlertVariant: PropTypes.string,
    formHeaderTitleTranslation: PropTypes.object,
    patientCancelButtonTranslation: PropTypes.object,
    patientSubmitButtonTranslation: PropTypes.object,
    errorGeneralMessageTranslation: PropTypes.object,
    isEditPatient: PropTypes.bool.isRequired,
    isReadOnlyForm: PropTypes.bool.isRequired,
    isPatientSubmitDisabled: PropTypes.bool,
    isErrorConflictAlert: PropTypes.bool.isRequired,
    isErrorConflictPopup: PropTypes.bool.isRequired,
    isErrorGeneralPopup: PropTypes.bool.isRequired,
    userClickedOnConfirmErrorGeneralPopup: PropTypes.func.isRequired,
    userClickedOnCancelConflictPopup: PropTypes.func.isRequired,
    userClickedOnConfirmConflictPopup: PropTypes.func.isRequired,
    userClickedOnCancelPatientForm: PropTypes.func.isRequired,
    userClickedOnSubmitPatientForm: PropTypes.func.isRequired,
    saveSuppressClearPatientFieldsDialog: PropTypes.func.isRequired,
    setSuppressClearPatientFieldsDialog: PropTypes.func.isRequired,
    userChangedInputPatient: PropTypes.func.isRequired,
    isGeneratedChartNumberRequired: PropTypes.bool,
    IsClearDataPopup: PropTypes.bool,
    isClearPatientFieldsDialogSuppressed: PropTypes.bool,
    userCallClearPatientAction: PropTypes.func.isRequired,
    userCallSearchPatient: PropTypes.func.isRequired,
    updateHostPatient: PropTypes.func.isRequired,
    userLoadedExistingPatient: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.addEventListener('touchmove', this.handleScroll);
  }

  componentDidUpdate() {
    this.updateGeneratedChartNumber(this.props.formValues);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleScroll);
  }

  getConflictPatientDetails = (dateOfBirthFormat, regulatoryDateOfBirthMask) => {
    const { conflictedPatient } = this.props;
    if (!conflictedPatient || !conflictedPatient.fullName) {
      return '';
    }

    const isGender = Number.isInteger(conflictedPatient.gender);
    const dateOfBirth = conflictedPatient.dateOfBirth;
    const chartNumber = conflictedPatient.chartNumber;

    const msg = (
      <>
        {<TranslatedContent contentArray={[conflictedPatient.fullName.trim()]} />}
        {isGender && ', '}
        {isGender && <TranslatedContent contentArray={[conflictedPatient.gender ? messages.female : messages.male]} />}
        {dateOfBirth && ', '}
        {dateOfBirth && utils.formatDateOfBirth(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask)}
        {chartNumber && ', '}
        {chartNumber && <TranslatedContent contentArray={[conflictedPatient.chartNumber]} />}
      </>
    );
    return msg;
  };

  getConflictPopupMessage = (dateOfBirthFormat, regulatoryDateOfBirthMask) => {
    const msg = (
      <>
        <TranslatedContent contentArray={[messages.conflictPopupMessage]} />
        <br />
        {this.getConflictPatientDetails(dateOfBirthFormat, regulatoryDateOfBirthMask)}
        <br />
        <br />
        <TranslatedContent contentArray={[messages.conflictPopupQuestion]} />
      </>
    );
    return msg;
  };

  updateGeneratedChartNumber = formValues => {
    if (!this.props.isGeneratedChartNumberRequired) return;

    if (!!formValues) {
      const generatedChartNumber =
        (formValues.firstName ? formValues.firstName[0] : '') +
        (formValues.lastName ? formValues.lastName[0] : '') +
        initialChartNumber;
      this.props.change(FieldName.CHART_NUMBER, generatedChartNumber);
    } else {
      this.props.change(FieldName.CHART_NUMBER, initialChartNumber);
    }
  };

  handleChange = debounce((e, isKeyField) => {
    const { formValues, companyId, isEditPatient, formLastInvalid, invalid, userChangedInputPatient } = this.props;
    this.updateGeneratedChartNumber(formValues);
    userChangedInputPatient({ form: formValues, companyId, isEditPatient, isKeyField, formLastInvalid, invalid });
  }, 300);

  handleFormSubmit = e => {
    const { formValues, patientId, companyId, conflictedPatient, userClickedOnSubmitPatientForm } = this.props;
    e.preventDefault();
    userClickedOnSubmitPatientForm({ form: formValues, patientId, companyId, conflictedPatient });
  };

  handleScroll = _ => {
    document.activeElement.blur();
  };

  handleConflictPopupConfirm = () => {
    const {
      formValues,
      patientId,
      patientType,
      companyId,
      conflictedPatient,
      userClickedOnConfirmConflictPopup
    } = this.props;
    userClickedOnConfirmConflictPopup({ form: formValues, patientId, patientType, companyId, conflictedPatient });
  };

  handleClearPatientDataClicked = () => {
    if (!this.props.dirty) {
      return;
    }
    const { userCallClearPatientAction, isClearPatientFieldsDialogSuppressed } = this.props;
    if (isClearPatientFieldsDialogSuppressed) {
      this.clearPatientData();
      return;
    }
    userCallClearPatientAction(true);
  };

  handleSearchPatientClicked = () => {
    const { userCallSearchPatient } = this.props;
    userCallSearchPatient();
  };

  handleConfirmClearPatientData = () => {
    const {
      userCallClearPatientAction,
      isClearPatientFieldsDialogSuppressed,
      saveSuppressClearPatientFieldsDialog
    } = this.props;
    if (isClearPatientFieldsDialogSuppressed) {
      saveSuppressClearPatientFieldsDialog();
    }
    this.clearPatientData();
    userCallClearPatientAction(false);
  };

  handleCancelClearPatientData = () => {
    const { userCallClearPatientAction, setSuppressClearPatientFieldsDialog } = this.props;
    setSuppressClearPatientFieldsDialog(false);
    userCallClearPatientAction(false);
  };

  handleDontShowClearDataPopupCheck = isChecked => {
    const { setSuppressClearPatientFieldsDialog } = this.props;
    setSuppressClearPatientFieldsDialog(isChecked);
  };

  clearPatientData = () => {
    const { userConfirmedClearingForm } = this.props;
    userConfirmedClearingForm();
  };

  loadExistingPatient = () => {
    const { conflictedPatient, userLoadedExistingPatient } = this.props;
    if (!conflictedPatient) {
      return;
    }
    userLoadedExistingPatient();
  };

  handleFieldBlur = (event, field) => {
    event.preventDefault();

    if (typeof field.normalize === 'function') {
      const normalizedValue = field.normalize(event.target.value);
      this.props.change(field.name, normalizedValue);
    }
  }

  render() {
    const {
      formFields,
      conflictedPatient,
      errorConflictAlertVariant,
      isEditPatient,
      isReadOnlyForm,
      isErrorConflictAlert,
      isErrorConflictPopup,
      isErrorGeneralPopup,
      errorGeneralMessageTranslation,
      userClickedOnCancelConflictPopup,
      userClickedOnConfirmErrorGeneralPopup,
      IsClearDataPopup,
      dateOfBirthFormat,
      regulatoryDateOfBirthMask
    } = this.props;
    return (
      <>
        <form
          autoComplete="off"
          onSubmit={this.handleFormSubmit}
          onScroll={this.handleScroll}
          ref={el => (this.patientForm = el)}
        >
          <InlineHeader
            clearHandler={this.handleClearPatientDataClicked}
            searchHandler={this.handleSearchPatientClicked}
            isDirty={this.props.dirty}
          />
          <div className={styles.formContainer}>
            {formFields.map(field => {
              const fieldProps = {
                conflictedPatient,
                readOnly: isReadOnlyForm || field.isDisabled,
                canShowErrorOnLoad: isEditPatient,
                maxLength: field.name === FieldName.LAST_NAME ? this.props.maxPatientLastNameLength : '',
                format: field.name === FieldName.DATE_OF_BIRTH ? dateOfBirthFormat : ''
              };

              return (
                field.isVisible && (
                  <div className={styles.inputField} key={field.name}>
                    <Field
                      key={field.name}
                      name={field.name}
                      component={DynamicInlineInputField}
                      validate={field.validations}
                      formFields={field}
                      props={fieldProps}
                      onChange={e => this.handleChange(e, field.isKeyField)}
                      onBlur={e => this.handleFieldBlur(e, field)}
                    />
                  </div>
                )
              );
            })}
          </div>
          <div className={styles.alert}>
            {isErrorConflictAlert && conflictedPatient && !isReadOnlyForm && (
              <Alert
                variant={errorConflictAlertVariant}
                content={
                  <>
                    <TranslatedContent contentArray={[messages.conflictHeader]} />{' '}
                    {this.getConflictPatientDetails(dateOfBirthFormat, regulatoryDateOfBirthMask)}
                  </>
                }
              >
                <span className={styles.conflict}>
                  <LinkLabel onClick={this.loadExistingPatient}>
                    <TranslatedContent contentArray={[messages.loadExistingPatientLink]} />{' '}
                  </LinkLabel>
                  <TranslatedContent contentArray={[messages.loadExistingPatientMessage]} />
                </span>
              </Alert>
            )}
          </div>
        </form>
        <QuestionPopup
          open={!!isErrorConflictPopup && !!conflictedPatient}
          handleCancelClick={userClickedOnCancelConflictPopup}
          cancelText={<TranslatedContent contentArray={[messages.no]} />}
          handleConfirmClick={this.handleConflictPopupConfirm}
          confirmText={<TranslatedContent contentArray={[messages.yes]} />}
          title={<TranslatedContent contentArray={[messages.question]} />}
          message={this.getConflictPopupMessage(dateOfBirthFormat, regulatoryDateOfBirthMask)}
        />
        <WarningPopup
          open={!!isErrorGeneralPopup}
          handleConfirmClick={userClickedOnConfirmErrorGeneralPopup}
          confirmText={<TranslatedContent contentArray={[messages.buttonOk]} />}
          title={<TranslatedContent contentArray={[messages.errorGeneralHeader]} />}
          message={<TranslatedContent contentArray={[errorGeneralMessageTranslation]} />}
        />
        <WarningPopup
          open={!!IsClearDataPopup}
          handleConfirmClick={this.handleConfirmClearPatientData}
          handleCancelClick={this.handleCancelClearPatientData}
          handleCheckboxChange={this.handleDontShowClearDataPopupCheck}
          confirmText={<TranslatedContent contentArray={[messages.buttonOk]} />}
          cancelText={<TranslatedContent contentArray={[messages.buttonCancel]} />}
          checkboxText={<TranslatedContent contentArray={[messages.checkboxDontShowAgain]} />}
          title={<TranslatedContent contentArray={[messages.confirmation]} />}
          message={<TranslatedContent contentArray={[messages.clearPatientDataConfirmation]} />}
          shouldEmitToHost={true}
          icon={NotificationIcons.Information}
        />
      </>
    );
  }
}

export const formName = 'PatientForm';

const initialChartNumber = Math.floor((new Date().valueOf() / 1000 / 60) % 1000000).toString();

const getInitialPatientData = state => {
  const { patientManagementInline } = state;
  const patientInitialData = { ...patientManagementInline.initialPatientData };
  if (patientManagementInline.isGeneratedChartNumberRequired) {
    patientInitialData.chartNumber = initialChartNumber;
  }
  return patientInitialData;
};

const mapStateToProps = state => {
  const { patientManagementInline, shell } = state;
  const maxPatientLastNameLength =
    shell.config.regulatorySettings && shell.config.regulatorySettings.maxPatientLastNameLength
      ? shell.config.regulatorySettings.maxPatientLastNameLength
      : '';

  return {
    patientId: shell.fromUrl.patientId,
    companyId: shell.fromUrl.companyId,
    patientType: shell.fromUrl.patientType,
    config: shell.config,
    initialValues: getInitialPatientData(state),
    formValues: getFormValues(formName)(state),
    formFields: patientManagementInline.formFields,
    conflictedPatient: patientManagementInline.conflictedPatient,
    patientCancelButtonVariant: patientManagementInline.patientCancelButtonVariant,
    errorConflictAlertVariant: patientManagementInline.errorConflictAlertVariant,
    formHeaderTitleTranslation: patientManagementInline.formHeaderTitleTranslation,
    patientCancelButtonTranslation: patientManagementInline.patientCancelButtonTranslation,
    patientSubmitButtonTranslation: patientManagementInline.patientSubmitButtonTranslation,
    errorGeneralMessageTranslation: patientManagementInline.errorGeneralMessageTranslation,
    formLastInvalid: patientManagementInline.formLastInvalid,
    isEditPatient: patientManagementInline.isEditPatient,
    isReadOnlyForm: patientManagementInline.isReadOnlyForm,
    isPatientSubmitDisabled: patientManagementInline.isPatientSubmitDisabled,
    isErrorConflictAlert: patientManagementInline.isErrorConflictAlert,
    isErrorConflictPopup: patientManagementInline.isErrorConflictPopup,
    isErrorGeneralPopup: patientManagementInline.isErrorGeneralPopup,
    isGeneratedChartNumberRequired: patientManagementInline.isGeneratedChartNumberRequired,
    maxPatientLastNameLength,
    IsClearDataPopup: patientManagementInline.IsClearDataPopup,
    isClearPatientFieldsDialogSuppressed: patientManagementInline.isClearPatientFieldsDialogSuppressed,
    dateOfBirthFormat: shell.fromUrl.dateOfBirthFormat
      ? shell.fromUrl.dateOfBirthFormat
      : shell.config.regulatorySettings
      ? shell.config.regulatorySettings.dobMask
      : fullDateOfBirthMask,
    regulatoryDateOfBirthMask: shell.config.regulatorySettings ? shell.config.regulatorySettings.dobMask : undefined
  };
};

const mapDispatchToProps = {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelConflictPopup,
  userClickedOnConfirmConflictPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient,
  userCallClearPatientAction,
  userCallSearchPatient,
  userConfirmedClearingForm,
  updateHostPatient,
  userLoadedExistingPatient,
  saveSuppressClearPatientFieldsDialog,
  setSuppressClearPatientFieldsDialog
};

PatientInlineForm = reduxForm({
  form: formName,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
})(PatientInlineForm);

PatientInlineForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInlineForm);

export default PatientInlineForm;
